.signIn .content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 64px - 88px); /* -Header -.app */
}

.signIn .signInDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 528px;
}

.signIn .app {
  text-align: center;
  border-bottom: 2px solid #d8d8d8;
  margin-bottom: 48px;
  padding: 14px 0;
  width: 100%;
}

.signIn .appName {
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 600;
}

.signIn .appVersion {
  font-weight: 600;
  font-size: 14px;
  color: #484d59;
}

.signIn label {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  color: #484d59;
  font-size: 14px;
  font-weight: 600;
}

.signIn .logInBtn {
  background: #009845;
  width: 146px;
  height: 36px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  font-weight: 600;
  border: none;
}

.signIn .signInForm {
  background-color: #fff;
  padding: 32px;
  width: 440px;
}

.signIn .title {
  display: flex;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 1px solid #d8d8d8;
  color: #484d59;
}

.signIn .title div:first-child {
  margin-right: 16px;
}

.signIn .titleIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #445e9c;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.titleText {
  font-size: 22px;
}

.signIn form label {
  margin: 16px 0;
  line-height: 32px;
}

.signIn form input {
  height: 40px;
  font-size: 14px;
  padding: 0 16px;
  color: #a4a9b4;
}

.signIn .errorMsg {
  color: #ef1d1d;
  font-size: 14px;
}

