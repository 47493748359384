.Table.dataTable,
.Table.nomenclature {
    font-size: 14px;
    color: #484d59;
    text-align: center;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #c9c9c9;
}

.Table.nomenclature tr:nth-last-child(2) {
  border-bottom: none !important;
}

.Table .filterRow {
    background-color: #ebedf3;
    height: 64px;
    text-transform: uppercase;
}

.Table .headerRow {
    height: 96px;
    background-color: #fff;
    border: 1px solid #b6b6b6;
    border-bottom: 2px solid #b6b6b6;
}

.Table .dataRow {
    background-color: #fff;
    height: 64px;
}

.Table .dataRow.isOdd {
    background-color: #f8f8f8;
}

.Table .rightBorder {
    border-right: 1px solid #ddd;
}

.Table .rightThickBorder {
    border-right: 2px solid #b6b6b6;
}

.Table .nomName .size {
    font-weight: 600;
    font-size: 14px;
    color: #818693;
    margin: 0 4px;
}

.Table .nomName .nomId {
    font-weight: 600;
    font-size: 14px;
    color: #4e535e;
    margin: 0 4px;
}

.Table .nomName .color {
    margin-right: 14px;
}

.Table .nomName .abc {
    font-weight: 600;
    font-size: 14px;
    color: #818693;
}

.Table .nomName .rightSection {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.nomName .rightSection :first-child {
    margin-left: 0;
}

.nomName .rightSection .icon {
    margin-left: 8px;
}

.Table .nomName {
    display: flex;
    align-items: center;
    padding: 0 18px;
    color: #484d59;
}

.Table .columnTitle {
    font-weight: 600;
    font-size: 12px;
    color: #484d59;
    text-align: center;
    text-transform: uppercase;
    background-color: #fff;
}

.Table .filterLabel {
    font-weight: 600;
    font-size: 14px;
    color: #06080f;
    text-align: left;
}

.Table .inProduction1 {
    line-height: 20px;
}

.Table .inProduction2 {
    line-height: 20px;
    color: #a4a9b3;
}

.Table .predictionAmount {
    line-height: 20px;
}

.Table .salesAppAmount {
    line-height: 20px;
    color: #a4a9b3;
}

.Table .inProduction1:hover,
.Table .inProduction2:hover,
.Table .clickable:hover {
    text-decoration: underline;
}

.Table .sumRow .inProduction1:hover,
.Table .sumRow .inProduction2:hover,
.Table .sumRow .clickable:hover {
    text-decoration: none;
}

.Table .predictionAmount,
.Table .predictionAmountSum {
    line-height: 20px;
}

.Table .predictionAmountSum {
    color: #a4a9b3;
}

.Table .filterRow .numberInput {
    padding-left: 16px;
}

.Table .sumRow {
    background: #ededed;
    border-bottom: 4px solid #b6b6b6;
    color: #000;
}

.Table .totalSumRow {
    background: #fff;
    border: none;
}

.Table .totalSumRow td > * {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 5px double #b6b6b6;
    height: 54px;
}

.Table .totalSumRow .idColumn {
    color: #a4a9b3;
}

.Table .multipleIcons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Table .percentageIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #445e9c;
    width: 28px;
    height: 28px;
    margin-left: 16px;
    cursor: pointer;
}

.Table .fetching {
    opacity: 0.5;
}

.Table .fetchingText {
    height: 64px;
}

.Table th {
    min-width: 100px;
}

.Table tbody tr:hover {
    background-color: #ebedf3 !important;
}

.Table .sticky {
    background-color: #ebedf3;
    position: sticky;
    top: -1px;
    z-index: 2;
    background-clip: padding-box;
}

.Table .sticky-row2 {
    position: sticky;
    top: 61px;
    z-index: 1;
    background-clip: padding-box;
}

.stickyBottom {
    position: sticky;
    bottom: -2px;
    z-index: 1;
    background-clip: padding-box;
    background-color: #ebedf3;
}