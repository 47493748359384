.ShoppingCartPage .backToSelectingCommodities {
    height: 64px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.09);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ShoppingCartPage .backToSelectingCommoditiesInner {
    padding: 0 32px;
    width: 100%;
}

.ShoppingCartPage .backLink {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    color: #484d59;
    text-decoration: none;
    text-transform: uppercase;
}

.ShoppingCartPage .backIcon {
    margin-right: 16px;
}

.ShoppingCartPage .shoppingCartHeading {
    height: 368px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ShoppingCartPage .shoppingCartHeading h1 {
    text-transform: uppercase;
    margin: 0;
    font-weight: 600;
    font-size: 32px;
    color: #818693;
}

.ShoppingCartPage .shoppingCartStats {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #a4a9b3;

    font-size: 20px;
    color: #484d59;
    margin: 28px 0;
}

.ShoppingCartPage .shoppingCartStats .iconCount {
    display: flex;
    align-items: center;
    margin: 24px;
}

.ShoppingCartPage .iconCount .icon {
    margin-right: 16px;
    width: 24px;
    height: 24px;
}

.ShoppingCartPage .shoppingCartActions {
    display: flex;
}

.ShoppingCartPage .exportBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #445e9c;
    width: 230px;
    height: 56px;
    font-size: 18px;
    color: #ffffff;
    margin: 0 16px;
    text-decoration: none;
    border: none;
}

.ShoppingCartPage .closePurchaseBtn {
    background: #009845;
    width: 230px;
    height: 56px;
    font-size: 18px;
    color: #ffffff;
    margin: 0 16px;
    border: none;
}

.ShoppingCartPage .startApprovalButton {
    background: #009845;
    width: 230px;
    height: 56px;
    font-size: 18px;
    color: #ffffff;
    margin: 0 16px;
    border: none;
}

.ShoppingCartPage .goToSuppliersBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: #ffaf00;
    width: 230px;
    height: 56px;
    font-size: 18px;
    color: #ffffff;
    border: none;
    margin: 0 16px;
}

.ShoppingCartPage .commoditiesList {
    padding: 0;
    margin-bottom: 32px;
}

.ShoppingCartPage .commoditiesList .commodityItem {
    display: flex;
    align-items: center;
    border: 1px solid #d8d8d8;
    background-color: #fff;
    height: 64px;
    padding: 0 32px;
}

.ShoppingCartPage .iconTextWrap {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.ShoppingCartPage .commodityItem .iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 28px;
}

.ShoppingCartPage .iconWrap.orange {
    background-color: #ffaf00;
}

.ShoppingCartPage .iconWrap.green {
    background-color: #009845;
}

.ShoppingCartPage .commodityItem .icon {
    width: 20px;
    height: 20px;
}

.ShoppingCartPage .commodityItem .endColumn {
    margin-left: auto;
}

.ShoppingCartPage .commoditiesList .commodityItem.header {
    font-size: 24px;
    color: #484d59;
}

.ShoppingCartPage .endColumnInfo {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #a4a9b3;
}

.ShoppingCartPage .time {
    margin: 0 56px;
}

.ShoppingCartPage .actionsMenu {
    margin-left: 36px;
    color: #484d59;
    margin-right: -8px;
    cursor: pointer;
}

.ShoppingCartPage .iconTextWrap .commodityImgSmall {
    width: 40px;
    height: 48px;
    margin-right: 20px;
}

.ShoppingCartPage .iconTextWrap .code {
    font-weight: 600;
    font-size: 18px;
    color: #818693;
    margin-right: 16px;
}

.ShoppingCartPage .iconTextWrap .name {
    font-weight: 600;
    font-size: 18px;
    color: #484d59;
}

.suppliersMenuIcon {
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    width: 2rem;
    height: 2rem;
}

.ShoppingCartPage .date {
    font-size: 18px;
    color: #a4a9b3;
    margin-left: 1rem;
}

.ImportDataConfirmPopup .heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #979797;
    padding: 0;
}

.ImportDataConfirmPopup .heading .iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #009845;
    border-radius: 50%;
    margin-right: 28px;
}

.ImportDataConfirmPopup .title {
    font-size: 24px;
    color: #484d59;
    line-height: 48px;
    text-align: left;
}

.ImportDataConfirmPopup .close {
    margin-left: auto;
    font-weight: 600;
    font-size: 18px;
    color: #484d59;
    line-height: 64px;
    text-transform: uppercase;
    cursor: pointer;
}

.ImportDataConfirmPopup .exclamationMarkIcon {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ef1d1d;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #FFF;
    font-size: 32px;
    margin-top: 64px;
    margin-bottom: 32px;
}

.ImportDataConfirmPopup .warningText {
    font-size: 18px;
    color: #484d59;
    line-height: 28px;
    text-align: left;
    margin-bottom: 32px;
}

.ImportDataConfirmPopup .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 72px;
}

.ImportDataConfirmPopup button {
    background: #009845;
    width: 230px;
    height: 56px;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 32px;
    border: none;
    cursor: pointer;
}

.ShoppingCartPage .fixedWrap {
    width: 100%;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ShoppingCartPage .bulkActions {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-right: 2rem;
}

.ShoppingCartPage .actionBtn {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #C4C4C4;
    margin-left: 1rem;
    padding: .5rem 1rem;
    font-size: 1rem;
    color: #484D59;
}

.ShoppingCartPage .actionIcon {
    margin-left: .5rem;
}

.ShoppingCartPage .checkbox {
    margin-right: 2rem;
}
