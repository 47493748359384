.contentWrapper {
  /* background-color: #fff; */
  padding: 0 32px;
}

.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}

.padding-left {
  padding-left: 1rem;
}