.Commodities {
    background-color: #fff;
    padding: 0 52px 40px;
    min-height: 160px;
}

.Commodities .plainMessage {
    padding: 32px 0;
}

.categoryHeading {
    margin: 0;
    font-size: 32px;
    color: #484d59;
    padding: 64px 0 20px;
    font-weight: normal;
}

.CommoditiesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 24px;
}

.Commodities .commodityBox {
    position: relative;
    text-align: center;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    padding: 28px;
}

.Commodities .commodityBox .code {
    font-weight: 600;
    font-size: 20px;
    color: #b4baca;
    line-height: 32px;
    margin-top: 16px;
}

.Commodities .commodityBox .name {
    font-weight: 600;
    font-size: 20px;
    color: #484d59;
    line-height: 32px;
    margin-bottom: 40px;
}

.Commodities .commodityBox .colors {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.Commodities .commodityBox .color {
    margin: 0 2px;
}

.Commodities .commodityBox .stateBadge {
    position: absolute;
    top: 24px;
    right: 24px;
}

.Commodities .commodityBox .stateBadge .icon {
    height: 24px;
}

.Commodities .commodityBox .sizes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
}

.Commodities .commodityBox .size {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    font-size: 14px;
    color: #484d59;
    text-align: center;
    line-height: 22px;
    background-color: #eaeaea;
    min-width: 24px;
    min-height: 24px;
    padding: 4px;
}

.Commodities .commodityBox:hover {
    cursor: pointer;
}

.Commodities .commodityBox.selected {
    border: 1px solid green;
}

.Commodities .imgWrap {
    min-height: 210px;
}

.Commodities .commodityImg {
    height: 210px;
}

.commodityNameCell {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #818693;
}

.commodityNameCell .commodityImgSmall {
    height: 40px;
    width: 32px;
    margin: 0 16px;
    font-size: 10px;
}

.commodityNameCell .name {
    color: #484d59;
}

.card-status {
  display: inline-block;
}