.Categories {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-gap: 16px;
    background-color: #fff;
    padding: 40px 20px;
}

.Categories .category {
    position: relative;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    padding: 44px;
    text-align: center;
    cursor: pointer;
}

.Categories .category .name {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    color: #484d59;
    text-align: center;
    line-height: 32px;
    margin-top: 16px;
    margin-bottom: 8px;
}

.Categories .category .count {
    font-size: 18px;
    color: #a4a9b3;
    text-align: center;
}

.Categories .category .subcount {
    font-size: 15px;
    color: #94c9a3;
    text-align: center;
}

.category-card-status {
  position: absolute;
  top: 24px;
  right: 24px;
}