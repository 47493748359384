body {
  margin: 0;
  padding: 0;
  background-color: #eceef4;
  font-family: Montserrat, sans-serif;
  color: #353B47;
}

input {
  font-family: Montserrat, sans-serif;
}

.hidden {
  display: none;
}

.lightGrayColor {
  color: #b4baca;
}

button:disabled {
  background-color: gray !important;
}

.fixedMaxSize {
  margin: 0 auto;
  max-width: 1440px;
}

.left {
  text-align: left !important;
}

.right {
  text-align: right !important;
}

.center {
  text-align: center;
}

.width100 {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
    text-decoration: underline;
}

.colorBlack {
  color: #000;
}
.colorRed {
  color: #f66;
}

.align-center {
  text-align: center !important;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}

.font-semibold {
  font-weight: 600 !important;
}

.wFullWithPadding {
  width: calc(100vw - 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

.flex {
  display: flex;
}
.flex-1 {
  flex: 1 0 0;
}
.flexWrap {
  display: flex;
  align-items: center;
}

.mr-16 {
  margin-right: 16px;
}