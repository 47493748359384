.NumberInput {
    display: flex;
    justify-content: center;
}

.NumberInput input {
    color: #484d59;
}

.NumberInput .errorBadge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    background-color: #ef1d1d;
    color: #FFF;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
}
