.screenTitle {
  font-size: 2rem;
}

.screenTitleComCode {
  color: #818693;
}

.grid {
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-template-rows: auto;
  grid-column-gap: 2rem;
}

.suppliers {
  background-color: #fff;
  top: 0;
  position: sticky;
  padding: 32px;
  max-height: 100vh;
  overflow-y: auto;
}

.suppliersHeader {
  font-size: 24px;
  color: #484D59;
  padding: 1rem 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid #B6B6B6;
}

.suppliersContentDesc {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.selectSupplierBtn {
  width: 100%;
  font-size: 18px;
  border: 1px solid #979797;
  padding: 16px 32px;
  background-color: #fff;
  text-align: left;
  margin: 0.5rem 0;
  cursor: pointer;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
}
.selectSupplierBtn:hover {
  background: #f4f4f4;
}

.selectedManufacturer {
  color: #fff;
  background-color: #445E9C;
  border: none;
  outline: none;
}
.selectedManufacturer:hover {
  background-color: #2d4378;
}

.nomSuppliersTable {
  width: 100%;
  border-collapse: collapse;
}

.nomRow {
  background-color: #F8F8F8;
  font-weight: bold;
  height: 48px;
}

.colorRow {
  background-color: #EDEDED;
  font-weight: bold;
  height: 48px;
  border-bottom: 1px solid #B6B6B6;
}

.nomRow:hover {
  background-color: rgba(235, 237, 243, .8);
}

.nomRowSelection {
  cursor: crosshair;
}

.supplierRow {
  background-color: #FFFFFF;
  height: 48px;
}

.supplierRow:hover {
  position: relative;
  background-color: rgba(235, 237, 243, .5);
  z-index: 50;
}

.nomName {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.nomColor {
  margin-right: 1.5rem;
}

.numberInput {
  text-align: right;
  border: none;
  font-size: 1rem;
  outline: none;
  width: 80px;
  background-color: transparent;
  margin-right: 2rem;
}

.addSupplier {
  cursor: pointer;
  color: #445E9C;
  font-weight: bold;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  outline: none;
}

.suppliersMenu {
  background-color: #fff;
  position: absolute;
  padding: 0;
  list-style: none;
  box-shadow: 0px 6px 30px rgba(78, 82, 110, 0.1);
  margin-top: 0
}

.suppliersMenuItem {
  color: #484D59;
  padding: 1rem;
}

.suppliersMenuItem:hover {
  color: #fff;
  background-color: #445E9C;
}

.suppliersMenuItemDetail {
  color: #445E9C;
}

.headerRow {
  font-size: .75rem;
}

.divider {
  border-top: 2px solid #979797;
}

.removeSupplier {
  color: #EF1D1D;
}

.plusColl {
  width: 1%;
  cursor: pointer;
}

.plusIcon {
  margin-left: 36px;
  margin-right: 42px;
  color: #445E9C;
  cursor: pointer;
}

.nomSize {
  color: #818693;
  margin-right: 1.5rem;
}

.nomForOrderColl {
  text-align: right;
  padding-right: 2rem;
  border-right: 1px solid #EDEDED;
}

.colorForOrderColl {
  text-align: right;
  font-weight: normal;
  padding-right: 2rem;
}

.colorLabel {
  color: #818693;
  font-weight: 600;
}

.nomenclatures {
  background-color: #fff;
  padding: 2rem 1rem;
}

.nomenclaturesHeader {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: normal;
  border-bottom: 1px solid #B6B6B6;
}

.nomenclaturesHeaderIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  margin-right: 1.25rem;
  background-color: #445E9C;
}

.commodityNameHeading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0;
}

.commodityBackLink {
  display: flex;
  align-items: center;
  color: #000;
  text-decoration: none;
  font-size: 1rem;
}

.goToSuppliersBtn {
  background: #445E9C;
  color: #fff;
  border: none;
  font-size: 1rem;
  padding: 1rem 2rem;
  text-decoration: none;
}

.nomenclaturesHeading {
  font-size: 1.5rem;
  font-weight: normal;
}

.nomThText, .nomThNumber {
  text-transform: uppercase;
  text-align: left;
  font-size: .75rem;
  padding: .5rem 0;
}

.nomThNumber {
  text-align: center;
  width: 1%;
}

.supplierButtons {
  display: flex;
  align-items: center;
}

.supplierBtnRemove {
  border: 1px solid #979797;
  background-color: #fff;
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
}
.supplierBtnRemove:hover {
  background: #f4f4f4;
}

.selectedManufacturerRemoval {
  color: #fff;
  background-color: #EF1D1D;
  border: none;
}
.selectedManufacturerRemoval:hover {
  background-color: #bc1414;
}

.buttonWithTextAndIcon {
  display: flex;
  align-items: center;
  border: 1px solid #979797;
  background-color: #fff;
  padding: .5rem 1rem;
  font-size: 1rem;
}

.btnIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: .5rem;
}

.selectedRemoveAllSuppliersBtn {
  color: #fff;
  background-color: #EF1D1D;
  border: none;
}

.nomCompletionsStatus {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  margin: 0 auto;
}

.colorCompletionsStatus {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
}

.completed {
  background-color: #009845;
}

.uncompleted {
  background-color: #FFAF00;
}

.not-assigned {
  background-color: #C4C4C4;
}

.overflow {
  background-color: #EF1D1D;
}

.totalCounts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  font-size: .875rem;
}

.totalCountsIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: .25rem;
}

.totalCountsIcon path {
  stroke: #fff;
  stroke-width: 2px;
}

.completedCount {
  background-color: #009845;
  color: #fff;
}

.inProgressCount {
  background-color: #FFAF00;
  color: #fff;
}

.commodityRow {
  border-top: 3px solid #B6B6B6;
  border-bottom: 3px solid #B6B6B6;
}

.commodityNameWrap {
  font-size: 1.25rem;
  font-weight: 600;
}

.commodityCode {
  color: #B6B6B6;
  margin-right: .5rem;
}

.borderRight {
  border-right: 1px solid #EDEDED;
}

.removeNomBtn {
  color: #EF1D1D;
}

.editNomBtn {
  color: #FFAF00;
}

.dupNomBtn {
  color: #445E9C;
}

.supplierWrap {
  position: relative;
  padding-left: .5rem;
  display: flex;
  align-items: center;
}
.supplierLineButtons {
  position: absolute;
  display: flex;
  right: 0;
  background: #f5f6f9; /* to match with tr:hover color */  
}
.supplierLineButtonsActive {
  z-index: 100;
}
.supplierLineIconButton {
  border: none;
  background: transparent;
  padding: .5rem;
  cursor: pointer;
}
.supplierLineIconButton:hover {
  background: #e5e6e9;
}

.supplierColumn {
  width: 100%;
}

.nomenclatureColl {
  width: 1%;
}

.supplierForOrderCol {
  text-align: right;
  border-right: 1px solid #EDEDED;
}

.removingText {
  color: #EF1D1D;
}

.nomColorSizeCol {
  width: 1%;
  white-space: nowrap;
}

.nomColorSize {
  display: flex;
  align-items: center;
}

.notEligibleManufacturer {
  opacity: .3;
  cursor: not-allowed;
}

/* confirm */

.confirmPopup {
  display: flex;
}

.confirmPopupHeading {
  display: flex;
}

.confirmPopupHeadingIconWrap {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #445E9C;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  flex-shrink: 0;
}

.confirmPopupHeadingTitle {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.confirmPopupHeadingBody {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.confirmPopupActions {
  display: flex;
  justify-content: flex-end;
}

.confirmPopupActionsPrimary {
  background-color: #445E9C;
  color: #fff;
  font-size: 1rem;
  padding: 1rem;
  border: none;
}

.confirmPopupActionsSecondary {
  background-color: #E8E8E8;
  font-size: 1rem;
  padding: 1rem;
  border: none;
  margin-right: 1rem;
  min-width: 200px;
}