.Filters {
}

.Filters .quickSearch {
    height: 60px;
    width: 472px;
    font-size: 32px;
    padding: 0 12px;
}

.Filters .selectFiltersWrapper {
    background-color: #fff;
    border-bottom: 2px solid #a4a9b3;
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 108px;
}

.Filters .selectFilters {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Filters .selectFiltersWrapper .iconWrap {
    background-color: #009845;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;
}

.Filters .cancelFilters:focus {
    border: none;
    outline: 0;
}

.Filters .cancelFilters {
    margin-left: auto;
    -webkit-appearance: none;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border: none;
    background-color: transparent;
    font-size: 18px;
    color: #009845;
    cursor: pointer;
}

.Filters .cancelFilters .icon {
    margin-right: 8px;
}

.Filters .input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 16px;
}

.Filters .input-group label {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    color: #484d59;
    line-height: 32px;
}

.Filters .Select-control {
    max-width: 500px;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Filters .Select-control input {
    font-size: 14px;
    line-height: 24px;
}

.Filters .Select-menu .Select-option {
    font-size: 12px;
    line-height: 24px;
}

.Filters .Select-control .Select-value {
    border: 1px solid rgba(68, 94, 156, 0.50);
    color: #445e9c;
    background-color: rgba(68, 94, 156, 0.10);
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Select-value {
    display: flex;
    align-items: center;
}

.Filters .Select-control .Select-value-icon,
.Filters .Select-control .Select-value-label {
    border: none;
}

.Filters .Select-control .Select-placeholder {
    font-size: 12px;
    line-height: 40px;
}

.Filters .Select-control .Select-value-icon,
.Filters .Select-control .Select-clear {
    font-size: 18px;
}

.Filters .quickSearchSection {
    height: 264px;
    justify-content: center;
    align-items: center;
}

.Filters .quickSearchSection label {
    text-transform: uppercase;
    font-size: 18px;
    color: #484d59;
    margin-bottom: 24px;
}
