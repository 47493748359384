.supplierRowTrans-enter-active {
  background-color: rgba(68, 94, 156, 0.1);
  transition: background-color 500ms;
}

.supplierRowTrans-exit-active {
  background-color: rgba(156, 55, 117, 0.1);
  transition: background-color 500ms;
}

.supplierRowTrans-enter-done {
  background-color: #fff;
  transition: background-color 500ms;
}