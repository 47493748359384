.ShoppingCart {
    display: flex;
    align-items: center;
    height: 64px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.09);
}

.ShoppingCartInner {
    display: flex;
    align-items: center;
    height: 64px;
    background-color: #fff;
    width: 100%;
    padding: 0 32px;
}

.ShoppingCart .iconCount {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #484d59;
    margin-right: 56px;
}

.ShoppingCart .icon {
    margin-right: 12px;
}

.ShoppingCart .shoppingCartBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #009845;
    width: 220px;
    height: 48px;
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
}

.ShoppingCart .cartStats {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.ShoppingCart .backLink {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    color: #484d59;
    text-decoration: none;
    text-transform: uppercase;
}

.ShoppingCart .backIcon {
    margin-right: 16px;
}

.ShoppingCart .importData {
    display: flex;
    align-items: center;
}

.ShoppingCart .importDataBtn {
    display: flex;
    align-items: center;
    margin-right: 16px;
    font-size: 18px;
    background-color: #fff;
    color: #4c639b;
    border: 1px solid #4c639b;
    padding: 0 16px;
    height: 48px;
    cursor: pointer;
}

.importDataBtn.btn-text-small {
  font-size: 13px;
}
.importDataBtn:disabled {
  color: #d8d8d8;
}

.btn-suffix {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -16px;
  margin-right: 16px;
  height: 48px;
  background-color: #fff;
  color: #4c639b;
  border: 1px dashed #ddd;
  border-left: none;
  padding: 0 16px;
}
.btn-suffix-large {
  height: 56px;
}

.ClosePurchasePopup .heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #979797;
    padding: 0 36px;
}

.ClosePurchasePopup .heading .iconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #009845;
    border-radius: 50%;
    margin-right: 28px;
}

.ClosePurchasePopup .title {
    font-size: 24px;
    color: #484d59;
    line-height: 48px;
    text-align: left;
}

.ClosePurchasePopup .close {
    margin-left: auto;
    font-weight: 600;
    font-size: 18px;
    color: #484d59;
    line-height: 64px;
    text-transform: uppercase;
    cursor: pointer;
}

.ClosePurchasePopup .exclamationMarkIcon {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ef1d1d;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #FFF;
    font-size: 32px;
    margin-top: 64px;
    margin-bottom: 32px;
}

.ClosePurchasePopup .warningText {
    font-size: 18px;
    color: #484d59;
    line-height: 28px;
    text-align: left;
}

.ClosePurchasePopup .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 72px;
}

.ClosePurchasePopup button {
    background: #009845;
    width: 230px;
    height: 56px;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 32px;
    border: none;
    cursor: pointer;
}

.ClosePurchasePopup .inputGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    margin-bottom: 32px;
}

.ClosePurchasePopup label {
    font-weight: 600;
    font-size: 16px;
    color: #484d59;
    line-height: 32px;
    text-transform: uppercase;
}

.ClosePurchasePopup .inputTitle {
    border: 1px solid #c4c4c4;
    width: 420px;
    height: 40px;
    font-size: 24px;
}
