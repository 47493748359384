.tableSection {
  overflow: auto;
  width: 100%;
  height: calc(100vh - 64px - 64px - 80px);
  box-sizing: border-box;
  background-color: #fff;
}

.table {
  width: 1%;
  background-color: #fff;
  font-size: 0.75rem;
  border-spacing: 0;
}

.leftFixedCol {
  position: sticky;
  left: 0;
  height: 1px;
  border: none;
  z-index: 100;
  padding: 0;
}

.topLeftCell {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1000;
  border-right: 1px solid #C4C4C4;
  border-bottom: 2px solid #445E9C;
}

.row {
  height: 2rem;
}

.nomCell {
  width: 1%;
  border-right: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
}

.numCell {
  text-align: center;
  font-size: 0.75rem;
  border-right: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
}

.headerCellGroups {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.headerSubRow {
  font-weight: normal;
  background-color: #F1F1F1;
  font-size: .75rem;
  border-bottom: 1px solid #fff;
  min-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 95px;
}

.topHeaderCell {
  min-width: 90px;
  width: 90px;
  max-width: 200px;
  position: sticky;
  top: 0;
  vertical-align: bottom;
  border-right: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  z-index: 1;
  height: 1px; /* fixed height */
  background-color: #fff;
  padding: 0;
  border-bottom: 2px solid #445E9C;
}

.secondLeftStickyCol {
  position: sticky;
  left: 271px;
  background-color: #fff;
  z-index: 1;
}

.thirdLeftStickyCol {
  position: sticky;
  left: 362px;
  background-color: #fff;
  z-index: 1;
}

.forthLeftStickyCol {
  position: sticky;
  left: 453px;
  background-color: #fff;
  z-index: 1;
  border-right: 2px solid #445E9C;
}

.fifthLeftStickyCol {
  position: sticky;
  left: 544px;
  background-color: #fff;
  z-index: 1;
}

.sumSticky {
  background-color: #F1F1F1;
}

.topHeaderLayer {
  z-index: 20;
  padding: 0 0 .5rem;
}

.topHeaderLabelCell {
  padding: 0 0 .5rem;
}

.orderType {
  background-color: #bfefc2;
  font-weight: 600;
}

.inquiryType {
  background-color: #f5dba3;
  font-weight: 600;
}

.nomLabel {
  display: flex;
  align-items: center;
  padding: auto 1rem;
}

.nomId {
  font-weight: 600;
  font-size: .75rem;
  color: #484D59;
  margin-right: .5rem;
}

.nomSize {
  font-size: .75rem;
  font-weight: 600;
  color: #858993;
  margin-right: .5rem;
}

.nomColorCode {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .5rem;
  margin-left: .5rem;
}

.sumRow {
  background-color: #F1F1F1;
  border-bottom: 2px solid #333;
}

.mainTitle {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: #858993;
  flex: 2;
}

.totalSumRow {
  position: sticky;
  bottom: 0;
  background-color: #445E9C;
  color: #fff;
  border-right: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  font-size: 0.75rem;
}

.colorFirstCol {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #F1F1F1;
  font-size: .75rem;
  font-weight: 600;
}

.nomFirstCol {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 100%;
  width: 270px;
}

.totalSumFirstCol {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  border: none;
  background-color: #445E9C;
  color: #fff;
  height: 100%;
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 600;
}

.stickyBottom {
  position: sticky;
  bottom: 0;
}

.supplierName {
  padding: .5rem 0;
  height: 100%;
}

.totalSumFirstColIcon {
  margin-right: 1rem;
  margin-left: .5rem;
}

.header {
  display: flex;
  align-items: center;
}

.backLink {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
  color: #484d59;
  text-decoration: none;
  text-transform: uppercase;
  flex: 1;
  padding-left: 1.5rem;
}

.referenceNumber {
  font-size: .875rem;
}