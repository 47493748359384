.numberInput {
    display: flex;
    align-items: center;
}

.label {
    max-width: 152px;
    font-size: 12px;
}

.controlGroup {
    display: flex;
    align-items: center;
    margin: 0 0 0 8px;
    flex-shrink: 0;
}

.input {
    border: none;
    width: 48px;
    height: 24px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    padding: 0;
    -webkit-appearance: none;
}

.action {
    border: none;
    margin: 0 8px;
    font-size: 14px;
    color: #fff;
    background-color: #009845;
    width: 24px;
    height: 24px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
