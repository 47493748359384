.Header {
  display: flex;
  align-items: center;
  background-color: #2e374d;
  height: 64px;
  color: #FFF;
  padding: 0 32px;
}

.Header .HeaderInner {
  display: flex;
  align-items: center;
  width: 100%;
}

.Header .imgHeadingWrap {
  display: flex;
  align-items: center;
}

.Header h1 {
  margin: 0 0 0 32px;
  font-size: 24px;
  font-weight: normal;
}

.Header .menu {
  margin-left: auto;
  color: #FFF;
  position: relative;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header .menu:focus {
  outline: none;
}

.Header .menuContent {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #fff;
  color: #000;
  z-index: 100;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.50);
}

.Header .menuItem {
  padding: 0 16px;
  line-height: 44px;
}
