.CommodityStateActionButton {
    background: #009845;
    width: 230px;
    height: 48px;
    font-size: 18px;
    color: #ffffff;
    text-align: center;
    border: none;
    cursor: pointer;
}

.CommodityStateActionButton .icon {
    width: 20px;
    height: 16px;
    margin-left: 16px;
}

.FillDataConfirmation .content {
    margin: 16px 16px 32px;
}

.FillDataConfirmation .actions {
    margin: 16px;
    text-align: right;
}

.FillDataConfirmation .checkbox {
    margin: 16px;
}

.FillDataConfirmation .confirmBtn {
    border: none;
    margin-left: 16px;
    font-size: 14px;
    color: #fff;
    background-color: #009845;
    padding: 8px 16px;
}

.FillDataConfirmation .cancelBtn {
    border: none;
    font-size: 14px;
    color: #000;
    background-color: #fff;
    padding: 8px 16px;
}

.table--condensed {
  width: 100%
}
.table--condensed th, .table--condensed td {
  text-align: left;
}