.CommodityPage .date {
    margin-top: 8px;
    color: #445e9c;
}

.idColumn {
    min-width: 200px;
    white-space: nowrap;
}

.negativeNumber {
    color: #fff;
    background-color: #ef1d1d;
    border: 1px solid #FFF;
}

.editableField,
.forOrderField {
    width: 96px;
    height: 48px;
    font-size: 14px;
    text-align: center;
    border: none;
    background-color: transparent;
    font-weight: bold;
}
.editableField {
  border: 1px solid rgba(0,0,0,.05);
  border-radius: .4rem;
  margin-left: 2px;
  margin-right: 2px;
}
.editableField:hover {
  border: 1px solid rgba(0,0,0,.15);
}

.forOrderField.isZero {
    color: #b4baca;
}

.commodityNameHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 220px;
}

.commodityNameHeading .backLink {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    color: #484d59;
    text-decoration: none;
    text-transform: uppercase;
    flex: 1;
    padding-left: 32px;
}

.commodityNameHeading h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #818693;
    line-height: 64px;
    text-align: center;
    flex: 2;
}

.commodityNameHeading .name {
    color: #484d59
}

.Nomenclatures {
    background-color: #fff;
    padding: 1px 32px 0;
}

.Nomenclatures .heading {
    height: 72px;
}

.Nomenclatures .shoppingActions {
    display: flex;
    justify-content: flex-end;
    padding: 32px 0;
}

.NomenclatureDrillDownPopup .close {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #009845;
    width: 230px;
    height: 48px;
    font-size: 18px;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    margin-left: 32px;
}

.NomenclatureDrillDownPopup .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
}

.NomenclatureDrillDownPopup table {
    width: 100%;
    border-collapse: collapse;
}

.NomenclatureDrillDownPopup th {
    height: 48px;
    font-weight: 600;
    font-size: 12px;
    color: #484d59;
    text-transform: uppercase;
    text-align: center;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8
}

.NomenclatureDrillDownPopup td {
    font-size: 14px;
    color: #484d59;
    text-align: center;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}

.NomenclatureDrillDownPopup th:first-child,
.NomenclatureDrillDownPopup td:first-child {
    border-left: none;
}

.NomenclatureDrillDownPopup th:last-child,
.NomenclatureDrillDownPopup td:last-child {
    border-right: none;
}

.NomenclatureDrillDownPopup tbody tr {
    height: 56px;
}

.NomenclatureDrillDownPopup tbody tr {
    border-top: 1px solid #d8d8d8;
}

.NomenclatureDrillDownPopup .sum {
    font-weight: bold;
}

.NomenclatureDrillDownPopup .mm-popup__box__body {
    padding: 36px 44px;
    min-height: 370px;
}

.NomenclatureDrillDownPopup .nomenclature {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #484d59;
}

.NomenclatureDrillDownPopup .nomenclature .color {
    margin-right: 24px;
}

.NomenclatureDrillDownPopup .nomenclature .id {
    margin-right: 16px;
    font-size: 16px;
}

.NomenclatureDrillDownPopup .nomenclature .size {
    font-size: 16px;
    margin-right: 16px;
    color: #818693;
}

.NomenclatureDrillDownPopup .nomenclatureCol {
    width: 40%;
    margin-right: 16px;
}

.NomenclatureDrillDownPopup .rows {
    display: flex;
    flex-wrap: wrap;
}

.NomenclatureDrillDownPopup .isInFuture {
    background-color: #eee;
}

.efficiencyVal {
    border-bottom: 1px solid #FFF;
}

.Nomenclatures .Table .out-of-stock {
    color: #FFF;
    background-color: #ef1d1d;
}

.Nomenclatures .Table .sufficient-stock {

}

.Nomenclatures .Table .insufficient-stock {
    color: #FFF;
    background-color: #ffaf00;
}

.CommodityPage .prevCommodityBtn,
.CommodityPage .nextCommodityBtn {
    cursor: pointer;
    background-color: #8096C6;
    color: #FFF;
    margin-right: 8px;
    width: 32px;
    height: 32px;
}

.CommodityPage .commodityNavBtns {
    display: flex;
    align-items: center;
    margin-left: 32px;
}

.forOrderVal {
    font-weight: bold;
}

.reportLink {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 1.125rem;
    text-decoration: none;
    color: #484d59;
    margin-right: 2.5rem;
}

.reportLinkIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
    background-color: #445e9c;
    border-radius: 50%;
    color: #fff;
    width: 2rem;
    height: 2rem;
}