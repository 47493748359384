.PurchaseHistory .heading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-weight: 600;
    font-size: 32px;
    color: #818693;
    text-align: center;
    line-height: 64px;
    text-transform: uppercase;
}

.PurchaseHistory .purchases {
    padding: 0;
    margin: 0;
}

.PurchaseHistory .purchaseItem {
    padding: 0 32px;
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 96px;
    border: 1px solid #d8d8d8;
    font-weight: 600;
    font-size: 18px;
    color: #484d59;
}

.PurchaseHistory .purchaseItem .icon {
    margin-left: auto;
    cursor: pointer;
}

.PurchaseHistory .purchaseItem .date {
    margin-right: 32px;
}

.PurchaseHistory .purchaseItem .time {
    margin-right: 46px;
}
